
let panels = document.querySelectorAll('.reason');
let wrapperHeight = (panels.length + 1) * window.outerHeight;
let offset = (panels.length + 1) * 50;

let endPx = wrapperHeight;

var tl = gsap.timeline({
  scrollTrigger: {
    trigger: '.reasons-wrapper',
    start: '140px 50px',
    end: "+=" + endPx + ' max',
    // snap: 1 / (panels.length - 1),
    pin: true,
    scrub: .6,
    // ease:'ease-in',
    invalidateOnRefresh: true,
    // markers: true,
    toggleActions: 'play complete restart reverse'
  },
  // start:'top 100%',
  // markers: "true",
  // toggleActions: "play none none reverse",
});

panels.forEach((panel, index) => {
  // gsap.set(panel, {
  //   top: -(index*100)+'%'
  // })

  tl.to(panel, {
    yPercent: -87,
    // ease:'ease-in',
    stagger: 1,
    // end: "+=20000",
    // snap: 1 / (panels.length - 1)
  })
});

// var outerTl = gsap.timeline({
//   scrollTrigger: {
//     trigger: '.reasons-wrapper',
//     start: 'top top',
//     end: '+=2000px',
//     pin: true,
//     scrub: .6,
//     ease:'ease-in',
//     toggleActions: 'play complete restart reverse'
//   },
//   // start:'top 100%',
//   // markers: "true",
//   // toggleActions: "play none none reverse",
// });

// document.querySelectorAll('.reasons-outer-wrapper').forEach((elem) => {
//   let panels = document.querySelectorAll('.reason').length;
//   // let offset = panels * (window.innerHeight / 10 );
//   let offset = panels * 60;
//   let wrapperHeight = document.querySelector('.reason:first-child').offsetHeight + offset;
//   // console.log(document.querySelector('.reasons-wrapper .pin-spacer:first-child').clientHeight)
//   // console.log(offset)
//   elem.setAttribute("style","height:"+wrapperHeight+"px");
// });


// console.log(document.querySelector('.pin-spacer:first-child').offsetHeight)
// -(index*100)+'%',


// gsap.to(".reason:not(:last-child)", {
//   yPercent: -100 * counter,
//   ease: "none",
//   stagger: 0.5,
//   scrollTrigger: {
//     trigger: ".reasons-wrapper",
//     start: "+-100%",
//     end: "+=100%",
//     scrub: true,
//     pin: true
//   }
// });