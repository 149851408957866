/*
-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
Accordions
━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
*/
(function($) {
  $(".panel-header").on('click', function (e) {
    $(this).next().slideToggle();
    $(this).parent().toggleClass('active')
  });

  $(".read-more").on('click', function (e) {
    // let target='#'+$(this).attr('data-target');

    if ( $(this).parent().hasClass('active') ) {
      $(this).parent().removeClass('active')
      $(this).find('.text').text('Read More')
    } else {
      $(this).parent().addClass('active')
      $(this).find('.text').text('Close')
    }
  });
})( jQuery );