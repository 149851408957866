console.log('🥑 %cMade by Monk', 'background: #616a2e; color: #f4e9e2; padding: 5px 17px; border-radius: 3px;');
console.log(' %chttps://monk.com.au ', 'padding: 5px 13px;');
import  './vendor/gsap/gsap';
import  './vendor/gsap/ScrollTrigger';
import  './vendor/gsap/SplitText';
import  './skip-link-focus-fix';
import  './smoothscroll';
import  './navigation';
import  './scrollyclasses';
import  './viewport-height';
import  './fw-video';
import  './sliders';
import  './accordions';
import  './fancybox';
import  './animations';
import  './gsapanims';
gsap.registerPlugin(ScrollTrigger, SplitText);

jQuery(function ($) {

	
	//page
	var $hamburger = $('.hamburger'),
			$site = $('body'),
			$menu = $('.main-navigation'),
			$menuitems = $('.menu-item'),
			$screenOverlay = $('.screen-overlay'),
			paths = document.querySelectorAll('.draw path');;

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Mobile Menu
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	$hamburger.on('click', function () {
		$hamburger.toggleClass('is-active');
		$site.toggleClass('menu-open');
		for (i = 0; i < paths.length; i++) {
			if ($('body').hasClass('menu-open')) {
				paths[i].classList.add('animate-in')
			} else {
				paths[i].classList.remove('animate-in')
			}
		}
		// if ( $('body').hasClass('menu-open') ) {
		// 	$('#mobile-menu .label').text('Close')
		// } else {
		// 	$('#mobile-menu .label').text('Menu')
		// }
	});
	//close menu with an outside click (basically anywhere on .site-content)
	function closeMenu() {
		$site.removeClass('menu-open');
		$menu.removeClass('toggled');
		$menuitems.removeClass('toggled-on');
		$hamburger.removeClass('is-active');
	}
	$screenOverlay.on('click', closeMenu);

	$(document).bind('keydown', function (e) {
		if (e.which == 27) {
			closeMenu();
		}
	});

	$('.cta a').on("click", function (e) {
		e.preventDefault();
		Calendly.showPopupWidget('https://calendly.com/d/dmq-xsc-9wg/15-minute-initial-greet-via-phone-call');
		return false;
	});

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Animate elements in on scroll
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	// $('.animate').on('inview', function (event, isInView) {
	// 	if (isInView) {
	// 		$(this).addClass('fadein');
	// 	}
	// });
  // AOS.init();

	var isInViewport = function (elem, offset = 0) {
		const rect = elem.getBoundingClientRect();
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

    const vertInView = (rect.top + offset <= windowHeight) && ((rect.top + rect.height) >= 0);
    const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

    return (vertInView && horInView);
	}
	function isHidden(el) {
    var style = window.getComputedStyle(el);
    return (style.display === 'none')
}


	for (i = 0; i < paths.length; i++) {
		var offset = paths[i].getAttribute('data-offset') == '' ? window.innerHeight/2 : 0;
		var lineLength = paths[i].getTotalLength();
		paths[i].style.strokeDasharray = lineLength;
		paths[i].style.strokeDashoffset = lineLength;
		// console.log(isHidden(paths[i].parentElement))
		// console.log(isInViewport(paths[i].parentElement, offset ))
		if (! isHidden(paths[i]) && isInViewport(paths[i].parentElement, offset ) ) {
			paths[i].classList.add('animate-in')
		}

	}

});
